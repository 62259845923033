import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

function BlogCard({ file, index, imageTop, tag }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [id, setId] = useState("");
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const maxLength = 120;

  useEffect(() => {
    const fetchBlogMetadata = async () => {
      try {
        setIsLoading(true);
        setIsError(false);

        const response = await fetch(file);
        if (!response.ok) throw new Error("File not found");

        const text = await response.text();
        const metadataRegex = /---\n([\s\S]*?)\n---/;
        const metadataMatch = text.match(metadataRegex);

        if (metadataMatch) {
          const metadata = metadataMatch[1].split("\n").reduce((acc, line) => {
            const [key, ...rest] = line.split(": ");
            acc[key] = rest.join(": ");
            return acc;
          }, {});

          // Multilingual handling
          const titleKey = lang !== "en" ? `Title-${lang}` : "Title";
          const descriptionKey =
            lang !== "en" ? `Description-${lang}` : "Description";

          setTitle(metadata[titleKey] || metadata.Title || "Untitled");
          setDescription(
            `${(metadata[descriptionKey] || "No description provided.").slice(
              0,
              maxLength
            )}...`
          );
          setTags(metadata.Tags ? metadata.Tags.split(", ") : []);
          setId(metadata.Id || "demoOne");
        }
      } catch (error) {
        console.error("Error fetching blog metadata:", error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlogMetadata();
  }, [file, lang]);

  if (isLoading) {
    return (
      <div className=" group flex flex-col w-full rounded-lg overflow-hidden transition-shadow shadow p-8 bg-white animate-pulse">
        <div className="relative overflow-hidden h-72 bg-gray-200" />
        <div className="flex flex-col flex-1">
          <div className="h-6 bg-gray-200 rounded w-3/4 mb-4" />
          <div className="h-4 bg-gray-200 rounded w-full mb-2" />
          <div className="h-4 bg-gray-200 rounded w-full mb-2" />
          <div className="h-4 bg-gray-200 rounded w-full mb-2" />
          <div className="h-4 bg-gray-200 rounded w-1/2 mb-2" />
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className=" group flex flex-col w-full rounded-lg overflow-hidden transition-shadow shadow p-8 bg-white">
        <div className="relative overflow-hidden h-72 bg-gray-200" />
        <div className="flex flex-col flex-1">
          <p className="text-red-600">
            Failed to load content. Please try again later.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ animationDelay: `${index / 5}s`, opacity: 1 }}
      className="blog-card group flex flex-col w-full rounded-lg overflow-hidden transition-shadow shadow hover:shadow-md"
    >
      {imageTop && (
        <div className="relative after:absolute after:top-0 after:w-full after:h-full after:bg-black/10 overflow-hidden h-72">
          <img
            src={imageTop}
            alt={title}
            className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105 group-focus:scale-105"
          />
          <span className="absolute top-2 left-2 bg-blue-600 text-white px-3 py-1 rounded-full text-sm">
            {tag}
          </span>
        </div>
      )}
      <div className="flex flex-col p-8 bg-white flex-1">
        <Link
          to={`/Insights/${id}`}
          className="max-w-full text-indigo-750 transition-colors text-xl font-bold font-heading"
        >
          {title}
        </Link>
        <p className="max-w-full text-justify font-body text-gray-500 my-4">
          {description}
          <Link
            to={`/Insights/${id}`}
            className="text-blue-600 hover:underline ml-1"
          >
            {t("insights.readMore")}
          </Link>
        </p>
        {tags.length > 0 && (
          <div className="flex flex-wrap mt-4">
            {tags.map((tag, i) => (
              <span
                key={i.toString()}
                className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
              >
                {tag.trim()}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default BlogCard;
